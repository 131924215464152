<template>
  <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">         
    <div class="container mt-5 mb-5 pb-2" id="custom-cards">
      <h3 class="pb-2 border-bottom text-dark-blue">Amounts Owend Report</h3>
      <div class="row col-md-12 justify-content-between mb-3">      
        <div class="col-4 input-group-sm text-start align-middle">
          <label for=""> Date</label>
          <v-date-picker v-model="range" :model-config="modelConfig" is-range @dayclick="onDayClick">
            <template v-slot="{ inputValue, inputEvents }">
              <input class="form-control input-h bg-white input-group-lg border px-2 py-1 rounded" :value="inputValue.start" v-on="inputEvents.start" />
            </template>
          </v-date-picker>
        </div>
      </div>     
      <div class="row row-cols-1 row-cols-md-3 mb-3 text-center">  
        <div class="col mb-3">
          <div class="rounded align-items-center h-30 shadow p-3 bg-body card-body">
            <h6 class="text-start card-title "><i class="far fa-clock"></i> Hours</h6>
            <p class="card-text text-start mb-2 text-orange">
              {{count}}
            </p> 
          </div>                                   
        </div>                    
        <div class="col mb-3 d-flex justify-content-around">
          <div class="rounded align-items-center h-30 shadow p-3 bg-body card-body">
            <h6 class="text-start card-title "><i class="fas fa-dollar-sign"></i> Amount</h6>
            <p class="card-text text-start mb-2 text-orange">
              $ {{total}}
            </p> 
          </div>                                   
        </div> 
        <div class="col mb-3">
          <div class="text-end">
            <!--<div class="cursor-pointer d-inline m-3" @click="mostrar = !mostrar ">
              Graphic <i class="fas fa-chart-bar"></i>
            </div>-->
            <div class="cursor-pointer d-inline m-3 dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
              Export <i class="fas fa-download"></i> 
            </div>
            <ul class="dropdown-menu">
              <div class="m-1">
                <li>
                  <a class="dropdown-item" href="#" @click="valueExcelSelected">To EXCEL</a>
                </li>
                <!--<li>
                  <a class="dropdown-item " href="#" @click="valuePdfSelected">To PDF</a>
                </li>-->
                <li>
                  <a class="dropdown-item " href="#" @click="valueCsvSelected">To CSV</a>
                </li>
              </div>                           
            </ul>   
          </div>
        </div> 
      </div>               
      <div id="graph" class="row m-2" v-show="mostrar">
        <div class="col-12" style="border:1px solid #CCC">
          Graphic
          <!-- Aqui irhia el grafico-->
        </div>
      </div>
      <div class="card card-cover overflow-hidden text-white bg-white shadow-sm p-3 mt-5 mb-5 mt-0 bg-body rounded" v-if="ListPayments.status != 'false'">
        <div class="table-responsive">
          <table class="table table-striped responsive" id="payments-table">
            <!--<thead class="  text-start ">
              <th class="fw-bold" colspan="3">Member: {{nameMember}}</th>
            </thead>-->
            <thead>
              <tr>
              <!--<tr class="text-start">-->
                <th></th>     
                <th scope="col"><span class="text-blue">Date</span></th>
                <th scope="col"><span class="text-blue">Hours</span></th>
                <th scope="col"><span class="text-blue">Amount</span></th>
              </tr> 
            </thead>
            <tbody class="mb-special shadow">
              <tr class="text-start" v-for="(obj, key, index) in ListPayments.list" :key="index">                         
                <td class="text-start">
                  {{obj.date}}
                </td>
                <td class="text-start">
                  {{obj.hours}}
                </td>
                <td class="text-start">
                  {{obj.amount}} $
                </td>
              </tr>                                  
            </tbody>
          </table>                            
        </div>
      </div>
    </div>          
  </main>
</template>

<script>
  import Api from '@/services/Request';
  import "datatables.net-dt/js/dataTables.dataTables";
  import "datatables.net-dt/css/jquery.dataTables.min.css";
  import Multiselect from '@vueform/multiselect'
  import moment from 'moment-timezone';
  import $ from 'jquery';
  import 'datatables.net-dt';

  export default 
  {  
    name: 'Payments',
    data: () => (
    {
      modelConfig: 
      {
        type: 'string',
        mask: 'YYYY-MM-DD', 
      },
      range: 
      {
        start: null,//new Date(),
        end: null//new Date()
      },
      ListPayments: 
      {
        status: "false",
        list: {}
      },
      mostrar: false,
      count: '-',
      total: '-',
      nameMember: '-',
      PostPayments: 
      {
        date_init: null,//new Date(),
        date_finish: null,//new Date(),
        timezone: moment.tz.guess(true),
      },
      GetExportPost: {
        date_init: null,
        date_finish: null,
        timezone: moment.tz.guess(true),
        download: 1,
        format: "",
      },
    }),
    methods: 
    {
      //Check Fields
      ValidateFields() 
      {
        if(this.PostPayments.date_init == null || this.PostPayments.date_finish == null)
        {
          return true
        }
      },
      valueExcelSelected() 
      {
        this.GetExportPost.format = "xlsx";
        if(this.ValidateFields() != true)
        {  
          this.sendExport();
        }
      },
      valueCsvSelected() 
      {
        this.GetExportPost.format = "csv";
        if(this.ValidateFields() != true)
        {  
          this.sendExport();
        }
      },
      sendExport() 
      {
        Api.post("/reports/amounts-owed", this.GetExportPost).then((result) => {
          if (result.data.status == "true") {
            /*if(result.data.link != undefined) {
              var win = window.open(result.data.link, '_blank');
            }*/
            var nameUrl = result.data.name;
            var fileLink = document.createElement('a');
            fileLink.href = result.data.link;
            document.body.appendChild(fileLink);
            fileLink.setAttribute('download', nameUrl);
            fileLink.click();
          }
        })
      },
      GetPaymentReport()
      {
        Api.post("/reports/amounts-owed", this.PostPayments).then((result) => 
        {

          this.ListPayments.status = result.data.status

          if (this.ListPayments.status == "true") 
          {
            //Exists Datatable?
            if ($.fn.dataTable.isDataTable('#payments-table'))
            {
              var table = $('#payments-table').DataTable();
              table.destroy();
            }

            this.count = result.data.hours;
            this.total = result.data.amount;
            this.ListPayments.list = result.data.list;

            this.$nextTick(() => 
            {
              this.InitDatatable()
            })
          }
        })
      },
      //Init second table
      InitDatatable() 
      {
        /*var table = $('#payments-table').DataTable(
        {
          dom: 'lftip',
          responsive: true,
          destroy: true,
          lengthChange: false,
          aaSorting: [ [0,'asc'] ]
        });*/
        let mydata = this.ListPayments.list;

        /* Formatting function for row details - modify as you need */
        function format(d) 
        {
          let getData = d.list;
          let trtable = "";

          for (var i = 0; i < getData.length; i++) 
          {
            trtable += '<tr>'+
            '<td>'+getData[i].pay_rate+'$</td>'+
            '<td>'+getData[i].hours+'</td>'+
            '<td>'+getData[i].account+'$</td>'+
            '</tr>';
          }

          // `d` is the original data object for the row
          return '<table cellpadding = "5" cellspacing = "0" border = "0" style = "width:100%;">'+
          '<thead>'+
          '<tr>'+
          '<th>Rate</th>'+
          '<th>Hours</th>'+
          '<th>Account</th>'+
          '</tr>'+
          '</thead>'+
          trtable + 
          '</table>';
        }

        var table = $('#payments-table').DataTable(
        {
          "data": this.ListPayments.list,
          "columns": [
          {
            "className": 'dt-control',
            "orderable": false,
            "data": null,
            "defaultContent": ''
          },
          {
            "data": "date"
          },
          {
            "data": "hours"
          },
          {
            "data": "amount"
          }],
        });

        $("#payments-table tbody td.dt-control").unbind('click');
        // Add event listener for opening and closing details
        // $('#payments-table tbody').on('click', 'td.dt-control', function() 
        $('#payments-table tbody td.dt-control').on('click', function() 
        {
          var tr = $(this).closest('tr');
          var row = table.row(tr);

          if (row.child.isShown()) 
          {
            // This row is already open - close it
            row.child.hide();
            tr.removeClass('shown');
          } 
          else 
          {
            // Open this row
            row.child(format(row.data())).show();
            tr.addClass('shown');
          }
        });
      },
      onDayClick(day) 
      {
        this.PostPayments.date_init = this.range.start;
        this.PostPayments.date_finish = this.range.end;

        this.GetExportPost.date_init = this.range.start;
        this.GetExportPost.date_finish = this.range.end;

        if(this.ValidateFields() != true)
        {  
          this.GetPaymentReport();
        }
      },
    },
    mounted() 
    {
      //
    }
  }
</script>

<style scoped></style>
